<template>
  <div>
    <component
      :is="computedComponentName"
      v-model="shouldShowModal"
      :close-on-content-click="false"
      :min-width="computedComponentName === 'v-menu' ? 270 : null"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex align-center justify-between filter-title"
          :class="{ 'font-weight-bold': isFilterApplied }"
          v-on="on"
          v-bind="attrs"
          >
          <div class="d-flex align-center flex-grow-1">
            <v-icon left>
              escalator_warning
            </v-icon>
            Age
          </div>
          <v-icon>
            expand_more
          </v-icon>
        </div>
      </template>
      <v-card>
        <v-card-title class="d-md-none">
          Age
        </v-card-title>

        <v-card-text
          class="pt-md-3"
          :class="{ 'black--text': isThemeLight }"
        >
          <!-- Audience Section -->
          <div
            v-for="kind in computedKinds"
            :key="kind"
            cols="12"
          >
            <div class="mb-1 d-flex">
              <div class="d-flex align-center flex-grow-1">
                <v-icon
                  :class="{ 'black--text': isThemeLight }"
                  class="mr-1"
                  left
                >
                  {{ kind === 'audience' ? 'people' : 'volume_down' }}
                </v-icon>

                {{ capitalizeString(kind) }}
              </div>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :color="isThemeLight ? 'black' : null"
                    v-bind="attrs"
                    v-on="on"
                  >
                    info
                  </v-icon>
                </template>

                <span>
                  {{ tooltipTexts[kind] }}
                </span>
              </v-tooltip>
            </div>

            <div class="d-flex justify-space-between">
              <age-selector
                v-model="form[kind].from"
                label="From"
                @change="submitForm(kind)"
                :max-value="form[kind].to"
              />
              <age-selector
                v-model="form[kind].to"
                label="To"
                @change="submitForm(kind)"
                :min-value="form[kind].from"
              />
            </div>

            <weight-selector
              v-if="kind === 'audience'"
              v-model="form[kind].weight"
              label="Percentage"
              @change="submitForm(kind)"
              class="my-3"
            />
          </div>
        </v-card-text>
      </v-card>
    </component>
  </div>
</template>

<script>
// Import child components
const AgeSelector = () => import(/* webpackChunkName: "age-selector" */ "@/blocks/common/selectors/AgeSelector.vue")
const WeightSelector = () => import(/* webpackChunkName: "weight-selector" */ "@/blocks/common/selectors/WeightSelector.vue")

// Name of this filter
const filterName = "age"

// Generator function for this form
const originalForm = () => ({
  audience: {
    from: 13,
    to: 100,
    weight: 0.05
  },
  influencer: {
    from: 13,
    to: 100,
    weight: 0
  }
})

// Export this SFC
export default {
  // Name of the component
  name: "FilterAge",

  // Register the children components
  components: {
    AgeSelector,
    WeightSelector
  },

  // Accept incoming data from parent
  props: {
    platform: {
      type: String,
      required: true
    }
  },

  // Define local data variables
  data: () => ({
    form: originalForm(),

    shouldShowModal: false,

    tooltipTexts: {
      audience: "Identify influencers by their audience age. We determine age of audience by analyzing profile photos and selfies in recent posts.",
      influencer: "Identify influencers by their age. We determine age by analyzing profile photos and selfies in recent posts."
    }
  }),

  // Define readonly data variables
  computed: {
    /**
     * Show the filter as a dialog for mobile dialogs
     *
     * @returns {Boolean}
     */
    computedComponentName() {
      return this.isDesktopDevice ? "v-menu" : "v-dialog"
    },

    /**
     * Get all the applied filters for each kind
     *
     * @returns {Object}
     */
    appliedFilterFor() {
      return {
        audience: this.$store.getters["influencerDiscovery/findFilterByTypeAndKind"](filterName, "audience"),
        influencer: this.$store.getters["influencerDiscovery/findFilterByTypeAndKind"](filterName, "influencer"),
      }
    },

    /**
     * Check if the filter has been applied
     *
     * @returns {Boolean}
     */
    isFilterApplied() {
      return Boolean(this.$store.getters["influencerDiscovery/findFiltersByType"](filterName).length)
    },

    /**
     * If this is not snapchat, let the user choose the audience
     *
     * @returns {Boolean}
     */
     shouldShowAudience() {
      return this.platform !== "snapchat"
    },

    /**
     * Get the filter types to be shown in the form
     *
     * @returns {Array}
     */
     computedKinds() {
      if (this.shouldShowAudience)
        return ['audience', 'influencer']
      else
        return ['influencer']
    },
  },

  // Define vuelidate validations
  validations: {
    form: {
      audience: {},
      influencer: {}
    }
  },

  // Define local method functions
  methods: {
    /**
     * Takes an item and generates the text based on kind and weight
     *
     * @param {String} kind
     * @param {Object} parameters
     * @returns {String}
     */
    computeFilterText(kind, { from, to, weight }) {
      return `${capitalizeString(kind)} Age: ${from}-${to === 100 ? '65+' : to} ${!weight ? '' : '| >' + (weight * 100) + '%'}`
    },

    /**
     * Push the data from either of the inputs to the filters array in Vuex Store
     *
     * @param {String} kind | Either "audience" or "influencer"
     * @returns {void}
     */
    async submitForm(kind) {
      await this.$v.form[kind].$touch()

      if (this.$v.form[kind].$anyError) return

      // if the values are minimum and maximum, remove the filter
      if (
        this.form[kind].from === 13 &&
        this.form[kind].to === 100 &&
        this.appliedFilterFor[kind]
      ) {
        this.$store.dispatch("influencerDiscovery/removeFilter", this.appliedFilterFor[kind].id)
      }
      else {
        // if there's some value, then update the filter
        this.$store.dispatch("influencerDiscovery/replaceFilter", {
          type: filterName,
          data: {
            color: "brown lighten-5",
            icon: "escalator_warning",
            iconColor: "brown",
            kind,
            text: this.computeFilterText(kind, this.form[kind]),
            inputs: {
              ...this.form[kind]
            }
          }
        })
      }
    },
  },

  /**
   * As soon as the component has been rendered
   *
   * @returns {void}
   */
  mounted() {
    // Subscribe to window event when the chip is closed
    window.addEventListener("removeFilter", (e) => {
      // If it's not the one we want, don't execute the code
      if (e.detail.module !== "influencerDiscovery" || e.detail.item.type !== filterName) return

      // Now that it is the one we want to see, continue
      this.form[e.detail.item.data.kind] = originalForm()[e.detail.item.data.kind]
    })

    // Subscribe to window event when the chip is closed
    window.addEventListener("addFilter", (e) => {
      // If it's not the one we want, don't execute the code
      if (e.detail.module !== "influencerDiscovery" || e.detail.item.type !== filterName) return

      // Now that it is the one we want to see, continue
      this.form[e.detail.item.data.kind] = e.detail.item.data.inputs
    })
  }
}
</script>
